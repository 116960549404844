import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux'
import PropTypes from 'prop-types';
import {isMobile} from 'react-device-detect';

//import UseCasesData from '../data/UseCasesData'
import './usecases.css';






function UseCases(props) {
     

    const getPicture = (picName) =>{
        const picUrl =  `${props.mediaUrl}${picName}`;
        return picUrl
      }

      const renderCase = (uc) => (
        <div key={uc.id} className={uc.id%2 === 0 ? "case-bg bg-bleu" : "case-bg bg-orange"}>
            <div className="useCaseContentWrapper" >
                <div className="useCaseImgHolder">
                    <img src={getPicture(uc.logo)} className="useCaseImg"  alt="Logo"/> 
                </div>
                <div className="useCaseTxtHolder">
                    <div className="plat italic blauw useCasePadding" dangerouslySetInnerHTML={{ __html: uc.naam}} />
                    <h1 className="h1 blauw useCasePadding" dangerouslySetInnerHTML={{ __html: uc.titel}} />
                    <div className="plat blauw useCasePadding" dangerouslySetInnerHTML={{ __html: uc.text}} />
                </div>
            </div>
        </div>
      );

      const renderCaseMob = (uc) => (
        <div key={uc.id} className={uc.id%2 === 0 ? "case-bg bg-bleu" : "case-bg bg-orange"}>
            <div className="useCaseContentWrapper" >
            <div className="useCaseImgHolder">
                    <img src={getPicture(uc.logo)} className="useCaseImg"  alt="Logo"/> 
                </div>
                <div className="useCaseTxtHolder">
                    <div className="plat italic blauw useCasePadding" dangerouslySetInnerHTML={{ __html: uc.naam}} />
                    <h1 className="h1 blauw useCasePadding" dangerouslySetInnerHTML={{ __html: uc.titel}} />
                    <div className="plat blauw useCasePadding" dangerouslySetInnerHTML={{ __html: uc.text}} />
                </div>
            </div>
        </div>
      );

      
        return (
            <div className="container useCases-bg">

                { window.gtag('event', 'page_view', {
                'page_title': 'Klantervaring',
                'screen_name' : 'Klantervaring'})
                }
               {props.usecases && !isMobile && props.usecases.map(renderCase)}
               {props.usecases && isMobile && props.usecases.map(renderCaseMob)}
            </div>   
        )
      
        
    
}

const mapStateToProps = state => (
    {
        usecases : state.content.usecases,
        mediaUrl : state.content.mediaUrl
    }
  )

export default withRouter(connect(mapStateToProps)(UseCases));

UseCases.contextTypes = {
  router: PropTypes.object,
}