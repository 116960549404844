import React, { useState } from 'react';
import Slider from 'rc-slider';
import {isMobile} from 'react-device-detect';
import 'rc-slider/assets/index.css';
import '../../containers/Locations/locations.css'
import loaderIcon from '../../image/loader.svg'

// const DUMMY_CENTER = {
//   lat: "52.214365",
//   lng: "5.969732"
// };

function PostcodeInput(props) {

    const [postcode, setPostcode] = useState('');
    const [distance, setDistance] = useState(1);
    const [pcMessage, setPcMessage] = useState('');
    const [center, setCenter] = useState('');
   

    const onClickhandler = () => {
      if(postcode.length < 6 && distance === ""){
        setPcMessage('Postcode niet ingevuld of ongeldig.');
      }else{
        props.callbackfunction(center.lat, center.lng, distance);
        window.gtag('event', 'locatie_zoeken', {
          'postcode': postcode,
          'afstand' : distance})
      }
        
    }

    const checkIfPostcodeIsFilled = () => {
      if(postcode.length < 6 ){
        setPcMessage('Postcode niet ingevuld of ongeldig.');
      }
      if(postcode.length >= 6 ){
        setPcMessage('');
      }
    }

      const returnRadius = (value) =>{
        setDistance(value);
        props.callbackDistance(value, center);
      }

      const getCenterpointFromPostcode = (postcode) =>{
        if (postcode.length >= 6 && center === ''){
              fetch(`https://maps.googleapis.com/maps/api/geocode/json?address=${postcode}&key=${props.apikey}`)
              .then(response => response.json())
              .then(items => {
                let data = items.results[0].geometry.location;
                
                if(data !== null){
                  setCenter(data);
                }else{
                  setPcMessage('Postcode niet gevonden.');
                  setPostcode('');
                }
                
            });
          }
      }

      const handleOnFocus = () => {
        setPcMessage('');
        setPostcode('');
        setDistance(1)
        setCenter('');
        props.callbackReset();
      }

      const renderWeb = ()=>(
        <div className="pc-input-wrapper">
            Type hier uw postcode
                <input 
                    className="pc-inputBoxTxt"
                    type="text"
                    defaultValue={props.defaultValue}
                    placeholder="1234XX"
                    maxLength={6}
                    value = {postcode}
                    onChange={e => setPostcode(e.target.value.toUpperCase())}
                    onBlur = {getCenterpointFromPostcode(postcode)}
                    onFocus = {handleOnFocus}
                />
                Afstand
                <Slider
                    className="pc-inputSlider"
                    min={1}
                    max={150}
                    value={distance}
                    onBeforeChange={checkIfPostcodeIsFilled} 
                    onChange={returnRadius}
                    disabled={postcode.length >= 6 ? false : true}
                />
                
                <div className="pc-distance" >
                {`${distance} KM`}
                </div> 
                <div className="loaderHolder">{ props.loading && <img src={loaderIcon} alt='' style={{width:'100%'}}/>}</div>
            <button className="pc-inputBtn" onClick={onClickhandler } disabled={postcode.length >= 6 ? false : true}>Toon Resultaat</button>
            {props.message && <div className="pc-message"  dangerouslySetInnerHTML={{ __html: props.message}}/>}
            {pcMessage !== '' && <div className="pc-message"  dangerouslySetInnerHTML={{ __html: pcMessage}}/>}
        </div>
      );

      const renderMob= ()=>(
        <div className="pc-input-wrapper">
          <div className="pc-input-rows">
            <div>Type hier uw postcode</div>
            <input 
                className="pc-inputBoxTxt"
                type="text"
                defaultValue={props.defaultValue}
                placeholder="1234XX"
                maxLength={6}
                value = {postcode}
                onChange={e => setPostcode(e.target.value.toUpperCase())}
                onBlur = {getCenterpointFromPostcode(postcode)}
                onFocus = {handleOnFocus}
            />
            </div>
            <div className="pc-input-rows">
              <div>Afstand</div>
              <Slider
                  className="pc-inputSlider"
                  min={1}
                  max={150}
                  value={distance}
                  onBeforeChange={checkIfPostcodeIsFilled} 
                  onChange={returnRadius}
                  disabled={postcode.length >= 6 ? false : true}
              />
              <div className="pc-distance" >{`${distance} KM`}</div> 
            </div>
            <div className="pc-input-rows">
              <div className="pc-message">
                {props.message && <div className="pc-message-txt" dangerouslySetInnerHTML={{ __html: props.message}}/>}
                {pcMessage !== '' && <div className="pc-message-txt"  dangerouslySetInnerHTML={{ __html: pcMessage}}/>}
              </div>
              <button className="pc-inputBtn" onClick={onClickhandler} disabled={postcode.length >= 6 ? false : true}  >Zoek</button>
          </div>
    </div>
      );

     

    return (
      <div style={{width:'100%', height:'100%'}}>
      {!isMobile && renderWeb()}
      {isMobile && renderMob()}
      </div>
    );
  }
  
  export default PostcodeInput;

