import React, {useEffect} from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import MainHeader from './components/TopNav/header';
import MobHeader from './components/TopNav/headerMob';
import { Link } from 'react-router-dom';
import Footer from './components/Footer/footer';
import CookieConsent from "react-cookie-consent";
import {isMobileOnly} from 'react-device-detect';
import {gatherData, gatherTarieven} from './Redux/Actions';
import Routes from './routes';
import './css/App.css';

const styleCookie = {
  background:{
    background:  'rgba(20, 20, 20, 1)',
    padding:'0% 15% 0% 30%',
  },
  button:{
    color: 'rgba(21, 43, 141, 1)',
    fontSize: "16px",
    borderRadius:'4px',
   
  },
}


function App() {

  const dispatch = useDispatch();
  useEffect(()=>{
    dispatch(gatherData());
    dispatch(gatherTarieven());
  },[dispatch]);


  return (
    <div className="App">
        { !isMobileOnly && <MainHeader />}
        { isMobileOnly && <MobHeader />}
          <div className="main-container">
            <Routes />
          </div>
           <Footer />
         <CookieConsent
            location="bottom"
            buttonText="Prima"
            cookieName="CookieConsent"
            style={styleCookie.background}
            buttonStyle={styleCookie.button}
            expires={150}
           >Deze website maakt gebruikt van cookies: <Link className="link-color-white" to="/privacy">MEER INFORMATIE</Link></CookieConsent>
    </div>
  );
}



export default App;

App.propTypes = {
  //dispatch: PropTypes.func.isRequired,
}

App.contextTypes = {
  router: PropTypes.object,
}