

function HomeButtonExtern(props) {
 
    return (
        <a className="home-teaserItem plat" href='https://dutchitalmedia.zendesk.com/hc/nl/articles/360017770037-Aanleverspecificaties' target={"blank"}>
            <img src={props.image} className="home-teaserItemImagebox" alt=""/>
            <div className="home-teaserbox-content">
            <span>{props.name}</span>
            </div>
        </a>
    );
  }
  
  export default HomeButtonExtern;