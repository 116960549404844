import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import {isMobile} from 'react-device-detect';
import SocialButtons from '../Buttons/socialButtons';

import './footer.css';


function Footer() {
  const renderWeb = () => (
    <div style={{width:'100%',height:'100%'}}>
      <div style={{display:'flex', width:'100%',height:'100%',justifyContent:'center', alignContent:'center',flexDirection:'row'}}>
        <SocialButtons />
        <div style={{display:'flex',justifyContent:'center', alignContent:'center'}}>
          <div className="footertext-bottom">Dutchital Media B.V. | KvK: 17166285</div>
      </div>
      </div>
      </div>
  );

  const renderMob = () => (
      <div style={{width:'100%',height:'100%',justifyContent:'center', alignContent:'center', flexDirection:'row'}}>
        <div className="footertext-bottom">
          <div> Dutchital Media B.V. | KvK: 17166285</div>
        </div>
    </div>
  );


  return (
    <footer className="footer">
          {!isMobile && renderWeb()}
          {isMobile && renderMob()}
    </footer>
  );
}

export default withRouter(Footer);


Footer.contextTypes = {
  router: PropTypes.object,
}