import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux'
import PropTypes from 'prop-types';

import {isMobile} from 'react-device-detect';

import './inspiratie.css';




function Inspiratie(props) {

  const renderWeb = (props) => (
    <div className="insp-container">
      <div className="insp-contentWindow">
        <div className="inspContent plat wit" dangerouslySetInnerHTML={{ __html: props.inspiratie.txt}}/>
        <div className="h1-home wit insp-titel-bew">Lichte animatie</div>
          <div className="insp-bg-oranje">
            <div className="inspVideoWrapper">
            {props.inspiratie.video && props.inspiratie.video.map(renderVideo)}
            </div>
          </div>
        <div className="h1-home wit insp-titel-stil">Banner stilstaand</div>
          <div className="insp-bg-blauw">
            <div className="inspStillWrapper">
              {props.inspiratie.still && props.inspiratie.still.map(renderStill)}
            </div>
          </div>
      </div>
    </div>
  );
  
  const renderMob = (props) => (
    <div className="insp-contentWindow">
    <div className="inspContent plat wit" dangerouslySetInnerHTML={{ __html: props.inspiratie.txt}}/>
    <div className="h1-home wit insp-titel-bew">Lichte animatie</div>
          <div className="insp-bg-oranje">
            <div className="inspVideoWrapper">
            {props.inspiratie.video && props.inspiratie.video.map(renderVideo)}
            </div>
          </div>
        <div className="h1-home wit insp-titel-stil">Banner stilstaand</div>
          <div className="insp-bg-blauw">
            <div className="inspStillWrapper">
              {props.inspiratie.still && props.inspiratie.still.map(renderStill)}
            </div>
          </div>
  </div>
  );

  const renderVideo = (item)=>{
    const videoUrl=`${props.mediaUrl}${item.name}`
    return(
        <video key={item.id} autoPlay loop muted playsInline className="insp-video-item">
          <source src={videoUrl} type="video/mp4"></source>
        </video>
    )
  }

  

  const renderStill = (item)=>{
    const stillUrl=`${props.mediaUrl}${item.name}`
    return(
      <img key={item.id} src={stillUrl} className="insp-still-item" alt="still"/>
    )
  }

  
    return (
    <div className="container inspiratie-bg">

    { window.gtag('event', 'page_view', {
        'page_title': 'Inspiratie',
        'screen_name' : 'Inspiratie'})
      }

      {props.inspiratie && !isMobile && renderWeb(props)}
      {props.inspiratie && isMobile && renderMob(props)}
    </div>

    );
  }

  const mapStateToProps = state => (
    {
      inspiratie: state.content.inspiratie,
      mediaUrl: state.content.mediaUrl,
    }
  )

  export default withRouter(connect(mapStateToProps)(Inspiratie));
  
  Inspiratie.contextTypes = {
    router: PropTypes.object,
  }