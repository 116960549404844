import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux'
import {isMobile} from 'react-device-detect';
import PropTypes from 'prop-types';


import MainHeaderButton from '../../components/TopNav/mainHeaderButton';
import Vestiging from '../../components/Vestiging/vestiging';
import './overons.css';

//import dataFilosofie from '../data/filosofie'
//import dataVacature from '../data/vacature'
//import dataOOH from '../data/outOfHome'

import disclaimer from '../../pdf/Disclaimer.pdf';
import privacyStatement from '../../pdf/PS-DM.pdf';
import privacyPolicy from '../../pdf/PP-DM.pdf';
import algemeneVoorwaarden from '../../pdf/AV-DM-2022.pdf';


function OverOns(props) {

  let startPage = props.location.state.startPage !== 'vacature'? 'ooh': props.location.state.startPage;
  

  const [pageSelect, setPageSelect] = useState(startPage);
  console.log(props.location.state.startPage)

  const renderWeb = (props) => (
    <div className="container overons-bg">
     <div className="overOns-navi">
      <MainHeaderButton mainPage={true} name="Out-Of-Home" clickHandler ={() => { setPageSelect('ooh') }}/>
      <MainHeaderButton mainPage={true} name="Onze filosofie" clickHandler ={() => { setPageSelect('filo') }}/>
      <MainHeaderButton mainPage={true} name="Bij ons komen werken" clickHandler ={() => { setPageSelect('vacature') }}/>
      <MainHeaderButton mainPage={true} name="Bedrijfs informatie" clickHandler ={() => { setPageSelect('company') }}/>
    </div>
    
      {props.ooh && pageSelect === 'ooh' && 
      <div className="overOns-mainContainer oo-bg-ooh">
         { window.gtag('event', 'page_view', {'page_title': 'aboutUs','screen_name' : 'Out of Home'})}
        <div className="overOns-content-wrap ">
          <h1 className="h1 wit" dangerouslySetInnerHTML={{ __html: props.ooh.titel}} />
          <div className="plat wit" dangerouslySetInnerHTML={{ __html: props.ooh.text}} />
        </div>
        </div>
      }
      {props.filosofie && pageSelect === 'filo' && 
      <div className="overOns-mainContainer oo-bg-filosofie">
        { window.gtag('event', 'page_view', {'page_title': 'aboutUs','screen_name' : 'Filosophie'})}
        <div className="overOns-content-wrap ">
          <h1 className="h1 wit" dangerouslySetInnerHTML={{ __html: props.filosofie.titel}} />
          <div className="plat wit" dangerouslySetInnerHTML={{ __html: props.filosofie.text}} />
        </div>
        </div>
      }
      {props.vacature && pageSelect === 'vacature' && 
      <div className="overOns-mainContainer oo-bg-3">
        { window.gtag('event', 'page_view', {'page_title': 'aboutUs','screen_name' : 'Vacature'})}
        <div className="overOns-content-wrap scroll-y" >
          <h1 className="h1 wit" dangerouslySetInnerHTML={{ __html: props.vacature.titel}} />
          <div className="plat wit" dangerouslySetInnerHTML={{ __html: props.vacature.text}} />
        </div>
        </div>
      }
      {pageSelect === 'company' &&  
      <div className="overOns-mainContainer oo-bg-4">
        { window.gtag('event', 'page_view', {'page_title': 'aboutUs','screen_name' : 'Bedrijfs Info'})}
        <div className="overOns-content-wrap scroll-y">
          <div >
          <h1 className="h1 wit" dangerouslySetInnerHTML={{ __html: props.aboutUs.title}}/>
          <div className="plat wit" dangerouslySetInnerHTML={{ __html: props.aboutUs.txt1}}/>
          <div className="plat wit" dangerouslySetInnerHTML={{ __html: props.aboutUs.txt2}}/>
          <div className="overOns-content-bottom">
              <div><a className="oranje button-oo navigation-OO" href={algemeneVoorwaarden} download="AV-DM-2022.pdf"><b>Algemene Voorwaarden</b></a></div>
              <div><a className="oranje button-oo navigation-OO" href={privacyStatement} download="PS-DM.pdf"><b>Privacy Statement</b></a></div>
              <div><a className="oranje button-oo navigation-OO" href={privacyPolicy} download="PP-DM.pdf"><b>Cookie Policy</b></a></div>
              <div><a className="oranje button-oo navigation-OO" href={disclaimer} download="Disclaimer.pdf"><b>Disclaimer</b></a></div>
          </div>
          </div>
          <Vestiging data={props.aboutUs.eindhoven} />
          <Vestiging data={props.aboutUs.denbosch} />
          <Vestiging data={props.aboutUs.assen} />
          <Vestiging data={props.aboutUs.nijmegen}/>
        </div>
        </div>
      }
     
    </div>
    );
    
    const renderMob = (props) => (
      <div className="container overons-bg">
     <div className="overOns-navi">
      <MainHeaderButton mainPage={true} name="OOH" clickHandler ={() => { setPageSelect('ooh') }}/>
      <MainHeaderButton mainPage={true} name="filosofie" clickHandler ={() => { setPageSelect('filo') }}/>
      <MainHeaderButton mainPage={true} name="vacature" clickHandler ={() => { setPageSelect('vacature') }}/>
      <MainHeaderButton mainPage={true} name="vestigingen" clickHandler ={() => { setPageSelect('company') }}/>
    </div>
    
      {props.ooh && pageSelect === 'ooh' && 
      <div className="overOns-mainContainer">
        { window.gtag('event', 'page_view', {'page_title': 'aboutUs','screen_name' : 'Out of Home'})}
        <div className="overOns-content-wrap scroll-y">
          <h1 className="h1 wit" dangerouslySetInnerHTML={{ __html: props.ooh.titel}} />
          <div className="plat wit" dangerouslySetInnerHTML={{ __html: props.ooh.text}} />
        </div>
        </div>
      }
      {props.filosofie && pageSelect === 'filo' && 
      <div className="overOns-mainContainer">
         { window.gtag('event', 'page_view', {'page_title': 'aboutUs','screen_name' : 'Filosophie'})}
        <div className="overOns-content-wrap scroll-y">
          <h1 className="h1 wit" dangerouslySetInnerHTML={{ __html: props.filosofie.titel}} />
          <div className="plat wit" dangerouslySetInnerHTML={{ __html: props.filosofie.text}} />
        </div>
        </div>
      }
      {props.vacature && pageSelect === 'vacature' && 
      <div className="overOns-mainContainer">
        { window.gtag('event', 'page_view', {'page_title': 'aboutUs','screen_name' : 'Vacature'})}
        <div className="overOns-content-wrap scroll-y" >
          <h1 className="h1 wit" dangerouslySetInnerHTML={{ __html: props.vacature.titel}} />
          <div className="plat wit" dangerouslySetInnerHTML={{ __html: props.vacature.text}} />
        </div>
        </div>
      }
      {pageSelect === 'company' &&  
      <div className="overOns-mainContainer">
         { window.gtag('event', 'page_view', {'page_title': 'aboutUs','screen_name' : 'Bedrijfs Info'})}
        <div className="overOns-content-wrap scroll-y">
          <div >
          <h1 className="h1 wit" dangerouslySetInnerHTML={{ __html: props.aboutUs.title}}/>
          <div className="plat wit" dangerouslySetInnerHTML={{ __html: props.aboutUs.txt1}}/>
          <div className="plat wit" dangerouslySetInnerHTML={{ __html: props.aboutUs.txt2}}/>
          <div className="overOns-content-bottom">
              <div><a className="oranje navigation-OO" href={algemeneVoorwaarden} download="AV-DM-2022.pdf"><b>Algemene Voorwaarden</b></a></div>
              <div><a className="oranje navigation-OO" href={privacyStatement} download="PS-DM.pdf"><b>Privacy Statement</b></a></div>
              <div><a className="oranje navigation-OO" href={privacyPolicy} download="PP-DM.pdf"><b>Cookie Policy</b></a></div>
              <div><a className="oranje navigation-OO" href={disclaimer} download="Disclaimer.pdf"><b>Disclaimer</b></a></div>
          </div>
          </div>
          <Vestiging data={props.aboutUs.eindhoven} />
          <Vestiging data={props.aboutUs.denbosch} />
          <Vestiging data={props.aboutUs.assen} />
          <Vestiging data={props.aboutUs.nijmegen}/>
        </div>
        </div>
      }
     
    </div>
    );

    return (
      <div className="contentWrapper">

      { window.gtag('event', 'page_view', {
        'page_title': 'Over ons',
        'screen_name' : 'Over ons'})
      }
         {props.aboutUs && !isMobile && renderWeb(props)}
         {props.aboutUs && isMobile && renderMob(props)}
      </div>
    );
  }

  const mapStateToProps = state => (
    {
      aboutUs: state.content.aboutUs,
      filosofie: state.content.filosofie,
      vacature: state.content.vacature,
      ooh: state.content.outofhome,

    }
  )
  
  export default withRouter(connect(mapStateToProps)(OverOns));


OverOns.contextTypes = {
  router: PropTypes.object,
}


