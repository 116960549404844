import React from 'react'
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import './topNavMob.css';

/* App.jsx */
class HeaderMob extends React.Component {
  constructor(props){
    super(props);
    this.state={
      menuOpen:false,
    }
  }
  
  handleMenuClick() {
    this.setState({menuOpen:!this.state.menuOpen});
  }
  
  handleLinkClick(l) {
    this.setState({menuOpen: false});
    if(l === 'Home'){this.props.history.push('/')}
    // if(l === 'Tarieven'){this.props.history.push('/tarieven')}
    if(l === 'Netwerken'){this.props.history.push('/locations')}
    if(l === 'Inspiratie'){this.props.history.push('/inspiratie')}
    if(l === 'Klantervaring'){this.props.history.push('/cases')}
    if(l === 'Over ons'){this.props.history.push('/aboutUs',{startPage:'ooh'})}
    if(l === 'Veelgestelde vragen'){this.props.history.push('/vragen')}
  }
  
  render(){

    const stylesRoot = {
      main: {
        display:'flex',
        flexDirection:'column',
        alignItems: 'center',
        height: '5%',
      }
    }
    const styles= {
        container:{
          position: 'absolute',
          top: 0,
          left: 0,
          zIndex: '20000',
          opacity: 0.9,
          display:'flex',
          alignItems:'center',
          justifyContent: 'space-between',
          background: 'rgba(255, 120, 0, 1)',
          width: '100%',
          height: '5%',
          color: 'white',
          paddingRight:'2%',
        },
        logo: {
          margin: '0px 0px 0px 0px',
        },
        body: {
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          width: '100vw',
          height: '100vh',
          filter: this.state.menuOpen ? 'blur(2px)':null,
          transition: 'filter 0.5s ease',
        },
      }

    const logo = this.props.location.pathname === "/" ? '': 'Dutchital Media';
    const menu = ['Home','Netwerken','Inspiratie','Klantervaring','Over ons', 'Veelgestelde vragen'];
    // const menu = ['Home', 'Tarieven','Netwerken','Inspiratie','Klantervaring','Over ons', 'Veelgestelde vragen'];

    const menuItems = menu.map((val,index)=>{
      return (
        <MenuItem 
          key={index} 
          delay={`${index * 0.1}s`}
          onClick={()=>{this.handleLinkClick(val);}}>{val}</MenuItem>)
    });
    
    return(
      <div style={stylesRoot.main}>
        <div style={styles.container}>
          <MenuButton open={this.state.menuOpen} onClick={()=>this.handleMenuClick()} color='white'/>
          <div style={styles.logo}>{logo}</div>
        </div>
        <Menu open={this.state.menuOpen}>
          {menuItems}
        </Menu>
        <div style={styles.body}>
        </div>
      </div>
    )
  }
}


/* MenuItem.jsx*/
class MenuItem extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      hover:false,
    }
  }
  
  handleHover(){
    this.setState({hover:!this.state.hover});
  }
  
  render(){
    const styles={
      container: {
        opacity: 0,
        animation: '1s appear forwards',
        animationDelay:this.props.delay,
      },
      menuItem:{
        fontFamily:'FuturaStd-Bold',
        fontSize: '1.2rem',
        padding: '1rem 0',
        margin: '0 5%',
        cursor: 'pointer',
        color: this.state.hover? 'gray':'#fafafa',
        transition: 'color 0.2s ease-in-out',
        animation: '0.5s slideIn forwards',
        animationDelay:this.props.delay,

      },
      line: {
        width: '90%',
        height: '1px',
        background: 'gray',
        margin: '0 auto',
        animation: '0.5s shrink forwards',
        animationDelay:this.props.delay,
        
      }
    }
    return(
      <div style={styles.container}>
        <div 
          style={styles.menuItem} 
          onMouseEnter={()=>{this.handleHover();}} 
          onMouseLeave={()=>{this.handleHover();}}
          onClick={this.props.onClick}
        >
          {this.props.children}  
        </div>
      <div style={styles.line}/>
    </div>  
    )
  }
}

/* Menu.jsx */
class Menu extends React.Component {
  constructor(props){
    super(props);
    this.state={
      open: this.props.open? this.props.open:false,
    }
  }
    
  UNSAFE_componentWillReceiveProps(nextProps){
    if(nextProps.open !== this.state.open){
      this.setState({open:nextProps.open});
    }
  }
  
  render(){
    const styles={
      container: {
        position: 'absolute',
        top: 0,
        left: 0,
        height: this.state.open? '95%': 0,
        width: '100vw',
        display: 'flex',
        flexDirection: 'column',
        background: 'rgba(21, 43, 141, 0.9)',
        opacity: 0.95,
        color: '#fafafa',
        transition: 'height 0.3s ease',
        zIndex: 2,
      },
      menuList: {
        paddingTop: '3rem',
      }
    }
    return(
      <div style={styles.container}>
        {
          this.state.open?
            <div style={styles.menuList}>
              {this.props.children}
            </div>:null
        }
      </div>
    )
  }
}

/* MenuButton.jsx */
class MenuButton extends React.Component {
  constructor(props){
    super(props);
    this.state={
      open: this.props.open? this.props.open:false,
      color: this.props.color? this.props.color:'black',
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps){
    if(nextProps.open !== this.state.open){
      this.setState({open:nextProps.open});
    }
  }
  
  handleClick(){
  this.setState({open:!this.state.open});
  
  }
  
  render(){
    const styles = {
      container: {
        height: '32px',
        width: '32px',
        display:'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        padding: '4px',
      },
      line: {
        height: '2px',
        width: '20px',
        background: this.state.color,
        transition: 'all 0.2s ease',
      },
      lineTop: {
        transform: this.state.open ? 'rotate(45deg)':'none',
        transformOrigin: 'top left',
        marginBottom: '5px',
      },
      lineMiddle: {
        opacity: this.state.open ? 0: 1,
        transform: this.state.open ? 'translateX(-16px)':'none',
      },
      lineBottom: {
        transform: this.state.open ? 'translateX(-1px) rotate(-45deg)':'none',
        transformOrigin: 'top left',
        marginTop: '5px',
      },       
    }
    return(
      <div style={styles.container} 
        onClick={this.props.onClick ? this.props.onClick: 
          ()=> {this.handleClick();}}>
        <div style={{...styles.line,...styles.lineTop}}/>
        <div style={{...styles.line,...styles.lineMiddle}}/>
        <div style={{...styles.line,...styles.lineBottom}}/>
      </div>
    )
  }
}


export default  withRouter(HeaderMob)

HeaderMob.contextTypes = {
  router: PropTypes.object,
}

HeaderMob.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
}