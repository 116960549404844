import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux'
import PropTypes from 'prop-types';
import {isMobile} from 'react-device-detect';
import logo from '../../image/DMlogoWit.png';
//import dataHome from '../data/home';
// import InstagramFeed  from 'react-ig-feed';
// import WelcomeMessage from '../../components/popup/welcomeMessage';
import 'react-ig-feed/dist/index.css';
import './home.css';

import SocialButtons from '../../components/Buttons/socialButtons';
import HomeButtons from '../../components/Buttons/homeButtons';
import HomeButtonExtern from '../../components/Buttons/homeButtonExtern';

import teaser1 from '../../image/teaser1small.jpg';
import teaser2 from '../../image/teaser2small.jpg';
import teaser3 from '../../image/teaser3small.jpg';
import teaser4 from '../../image/teaser4small.jpg';

// const instaToken = "IGQVJVRjNXY3pDeTJrVUwzYmN5STdOZA0dIak1NVVlMYzF4MVhaODF4dnBFdWV4ejBNM29CY0Q1N2M2dnZA3bGtfTUJlVkw3b3lKOU9nanRBb0w1bHp6bFB2YVlqV1owNTV5Vy1YWmhDc2p5S3MwMXN6UgZDZD";

function Home(props) {

  // const [showWelcomeMessage, setWelcomeMessage] = useState(true);

  // const actionWelcomeMessage = (message) =>{
  //   setWelcomeMessage(message);
  //  }


  const renderWeb = (props) => (
   
    <div className="contentWrapper">
      <div className="titleboxhome">
        <img src={logo} alt="Logo" className="logoHome"/>
      </div>
      <div className="home">
        <h1 className="h1-home title-home" dangerouslySetInnerHTML={{ __html: props.home.titel}} />
        <div className="plat" dangerouslySetInnerHTML={{ __html: props.home.text}}/>
        <div className="home-teaserbox">
          <HomeButtons name="Ons netwerk" image={teaser1} clickHandler={() => {props.history.push('/locations') }}/>
          <HomeButtons name="Vacature" image={teaser4} clickHandler={() => {props.history.push('/aboutUs',{startPage:'vacature'}) }}/>
          <HomeButtons name="Over ons" image={teaser2} clickHandler={() => {props.history.push('/aboutUs',{startPage:'ooh'}) }}/>
          <HomeButtonExtern name="Aanleverspecificaties" image={teaser3}/>
        </div>
        <div className="home-ig-posts-box">
            <div className="wit plat home-titel-ig">Volg <a className="link-color-white" target="_blank" rel="noreferrer" href="https://instagram.com/dutchitalmedia">@dutchitalmedia</a> op Instagram!</div>
            {/* <InstagramFeed token={instaToken}  counter="12"/>  */}
        </div> 
      </div>
      <div style={{width:'100%',height:'1%', padding:'10px'}}/>
    </div>
  );


  const renderMob = (props) => (
    <div className="contentWrapper">
      <div className="titleboxhome">
        <img src={logo} alt="Logo" className="logoHome"/>
      </div>
      <h1 className="h1 wit title-home" dangerouslySetInnerHTML={{ __html: props.home.titel}} />
      <div className="home">
        <div className="plat wit" dangerouslySetInnerHTML={{ __html: props.home.text}}/>
        <SocialButtons />
      </div>
      <div className="home-ig-posts-box">
        <div className="wit plat home-titel-ig">Volg <a className="link-color-white" target="_blank" rel="noreferrer" href="https://instagram.com/dutchitalmedia">@dutchitalmedia</a> op Instagram!</div>
        {/* <InstagramFeed token={instaToken}  counter="6"/>  */}
      </div>
    </div>
    
  );
 

  return (
    <div className="container-home home-bg">
      { window.gtag('event', 'page_view', {
        'page_title': 'Home',
        'screen_name' : 'Home'})
      }

       {props.home && !isMobile && renderWeb(props)}
       {props.home && isMobile && renderMob(props)}
       {/* {props.home && !isMobile && <WelcomeMessage show={showWelcomeMessage} action={actionWelcomeMessage}/>} */}
    </div>
  );
}

const mapStateToProps = state => (
  {
    home: state.content.home,
  }
)

export default withRouter(connect(mapStateToProps)(Home));

Home.contextTypes = {
  router: PropTypes.object,
}

Home.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
}