
import PropTypes from 'prop-types';
import { withRouter} from 'react-router-dom';

import MainHeaderButton from './mainHeaderButton';
import MainHeaderButtonHelpdesk from './mainHeaderButtonHelpdesk';
import logo from '../../image/DMlogoKleur.png';

const style = {
  
  root:{
    width: "100%",
    height: "5%",
    backgroundColor: "rgba(250, 250, 250, 0.8)",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    padding: "0px 0px 0px 0px",
    margin: "0px 0px 0px 0px",
  },
  rootHome:{
    width: "100%",
    height: "5%",
    backgroundColor: "rgba(21, 43, 141, 1)",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    padding: "0px 0px 0px 0px",
    margin: "0px 0px 0px 0px",
  },
  logoDiv:{
      width: "30%",
      height:"100%",
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      padding:"5px 5px 5px 20px",
  },
  buttonWrapper:{
      width: "70%",
      height:"100%",
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
    },
    logo:{
      height: "50%",
    } 
}


function MainHeader(props) {
  const mainPage = props.location.pathname === "/" ? true : false;
  const rootStyle = mainPage ? style.rootHome : style.root;

  

  
    return (
      <div style={rootStyle}>
        {!mainPage && 
          <div style={style.logoDiv}>
            <img  src={logo} alt="logo" style={style.logo}/>
          </div>}
        <div style={style.buttonWrapper}>
          <MainHeaderButton mainPage={mainPage} name="home" clickHandler ={() => {props.history.push('/') }}/>
          {/* <MainHeaderButton mainPage={mainPage} name="tarieven" clickHandler={() => {props.history.push('/tarieven') }}/> */}
          <MainHeaderButton mainPage={mainPage} name="netwerk" clickHandler={() => {props.history.push('/locations') }}/>
          <MainHeaderButton mainPage={mainPage} name="inspiratie" clickHandler={() => {props.history.push('/inspiratie') }}/>
          <MainHeaderButton mainPage={mainPage} name="klantervaring" clickHandler={() => {props.history.push('/cases') }}/>
          <MainHeaderButton mainPage={mainPage} name="over ons" clickHandler={() => {props.history.push('/aboutUs',{startPage:'ooh'}) }}/>
          <MainHeaderButtonHelpdesk mainPage={mainPage} name="veelgestelde vragen"/>
      </div>
      </div>
    );
  }
  
  export default withRouter(MainHeader);

  MainHeader.propTypes = {
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
  }