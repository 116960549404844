
import './topNav.css';

function MainHeaderButtonHelpesk(props) {

    const helpdeskUrl = "https://dutchitalmedia.zendesk.com/hc/nl";
    const target = "_blank"; //_self is in huidig tab, _blank open een nieuwe tab

    return (
      
      <button className={props.mainPage ? "headerButton " : "headerButton2 "} >
        <a className={props.mainPage ? "helpdesk" : "helpdesk2"} href={helpdeskUrl} target={target}>{props.name}</a>
      </button>
      
    );
  }
  
  export default MainHeaderButtonHelpesk;