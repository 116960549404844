//import '../css/mobile.css'
//import icon from '../../image/iconEmployee.png'

import './vestiging.css';


function Vestiging(props) {

// const renderEmployees = (employee)=> {
//     return (
//         <div key={employee.id}>
//             <div  className="vs-employeeContainer">
//                 {/* <img  src={`https://cmsdmmedia.jhhnts.nl/${employee.avatar}` } alt="icon" className="vs-employeeIcon"/> */}
//                 <div className="vs-employeeAva" style={{backgroundColor: `#${(Math.random()*0xFFFFFF<<0).toString(16)}`,}}>
//                     {employee.name.charAt(0)}
//                 </div>
//                 <div className="vs-infoContainer wit">
//                     <div>{employee.name}</div>
//                 </div>
//             </div>
//         </div>
//     );
//   }
//employees data to alphabetic order
//   const employeesUnsorted = props.data.employees;
//   const employees = employeesUnsorted.sort(function (a, b){
//         var nameA=a.name.toLowerCase(), nameB=b.name.toLowerCase()
//         if (nameA < nameB) return -1 
//         if (nameA > nameB) return 1
//         return 0
//     });
  
    return (
        <div className="vs-root">
            <div className="vs-top-company-wrapper">
                <img src={`media/${props.data.info.image}`} className="vs-pand" alt="vestiging"/>
                <div className="cont-top-vest1" style={{padding:'2% 0% 0% 2%'}}>
                    <h1 className="h1 wit">{props.data.info.vestiging}</h1>
                    <div className="adresbox-OO plat wit">
                        <div>{props.data.info.straat}</div>
                        <div>{props.data.info.postcode} {props.data.info.stad}</div>
                        <div>{props.data.info.telefoon}</div>
                    </div>
                </div>
            </div>
            {/* <div className="vs-employees-wrapper">
                {employees.map(renderEmployees)}
            </div> */}
        </div>

    );
  }
  
  export default Vestiging;