import { TARF, TARF_ERROR } from '../Actions/tarieven'

const defaultState = {}

const tarief_data = (state = defaultState, action) => {
  switch (action.type) {
    case TARF_ERROR:
      return {
        ...action.data,
      }
    case TARF:
      return {
        ...state,
        ...action.data,
      }
    default:
      return state
  }
}

export default tarief_data