import React from 'react';
import { withRouter } from 'react-router-dom';
import {isMobile} from 'react-device-detect';
import PropTypes from 'prop-types';

import '../OverOns/overons.css';

import dataCookie from '../data/cookie'




function Cookie() {
  const renderWeb = () => (
    <div className="container overons-bg">
      <div className="overOns-mainContainer oo-bg-ooh" style={{height: '100%'}}>
        <div className="overOns-content-wrap scroll-y">
          <h1 className="h1 wit" dangerouslySetInnerHTML={{ __html: dataCookie.title}} />
          <div className="plat wit" dangerouslySetInnerHTML={{ __html: dataCookie.txt}} />
        </div>
       </div>
    </div>
    );
    
    const renderMob = () => (
      <div className="container overons-bg">
        <div className="overOns-mainContainer" style={{height: '100%'}}>
        <div className="overOns-content-wrap scroll-y">
          <h1 className="h1 wit" dangerouslySetInnerHTML={{ __html: dataCookie.title}} />
          <div className="plat wit" dangerouslySetInnerHTML={{ __html: dataCookie.txt}} />
        </div>
        </div>
    </div>
    );

    return (
      <div className="container overons-bg">

      { window.gtag('event', 'page_view', {
        'page_title': 'Over ons',
        'screen_name' : 'Over ons'})
      }
         {!isMobile && renderWeb()}
         {isMobile && renderMob()}
      </div>
    );
  }


export default withRouter(Cookie);

Cookie.contextTypes = {
  router: PropTypes.object,
}


