import React from 'react';
import { Route, Switch } from 'react-router-dom';

import asyncComponent from './components/asyncComponent';
import Home from './containers/Home/home2';
import Inspiratie from './containers/Inspiratie/inspiratie2';
import Locations from './containers/Locations/locations';
import OverOns from './containers/OverOns/overOns';
import UseCases from './containers/UseCases/useCases';
import Cookie from './containers/cookie/cookie';
import Tarieven from './containers/Tarieven/tarieven';

// Error handling
const Error = asyncComponent(() => import('./components/error'))
const NotFound = asyncComponent(() => import('./components/notFound'))


const Routes = () => (
  <Switch>
    <Route exact path="/" component={Home} />
    <Route path="/inspiratie" component={Inspiratie}  />
    <Route path="/aboutUs" component={OverOns}  /> 
    <Route path="/locations" component={Locations}  /> 
    <Route path="/cases" component={UseCases}  /> 
    <Route path="/privacy" component={Cookie}  />
    <Route path="/tarieven" component={Tarieven}  /> 
    <Route path='/vragen' component={() => { window.location = 'https://dutchitalmedia.zendesk.com/hc/nl/articles/360017770037-Aanleverspecificaties'; return null;} }/>
   
    <Route path="/error" component={Error} />

    { /* catch all unmatched routes */}
    <Route component={NotFound} />
  </Switch>
)

export default Routes;

