/* eslint no-console: off */
/* eslint no-mixed-operators: off */
//import fetcher from '../../middleware/fetcher'

export const CONT = 'CONT';
export const CONT_ERROR = 'CONT_ERROR';

export const contentSuccess = data => ({ type: CONT, data })
export const contentError = data => ({ type: CONT_ERROR, data })


// const getContentData = async () => {
//   const response = await fetcher()
 
//   const data = await response.json();
//   return data;
// }

// export const gatherData = () => async (dispatch) => {
//   const response = await getContentData()
//   if (response.status === 0) {
//     const e = {}
//     e.errors = response.errors
//     dispatch(contentError(e))
//     console.log('error-> ',e)
//   }
//   if (response.status === 1) {
//     dispatch(contentSuccess(response.data))
//     //console.log('response-> ', response.data)
    
//   }

export const gatherData = () => async (dispatch) => {
  
  fetch('data/site_content.json').then((res)=>res.json()).then((response)=>{
    dispatch(contentSuccess(response.data))
   })

}
 




