/* eslint no-console: off */
/* eslint no-mixed-operators: off */
//import fetcher from '../../middleware/fetcher'

export const TARF = 'TARF';
export const TARF_ERROR = 'TARF_ERROR';

export const tariefSuccess = data => ({ type: TARF, data })
export const tariefError = data => ({ type: TARF_ERROR, data })

export const gatherTarieven = () => async (dispatch) => {
  
  fetch('data/tarieven.json').then((res)=>res.json()).then((response)=>{
    dispatch(tariefSuccess(response.data))
   })

}
 




