
import './topNav.css';

function MainHeaderButton(props) {

    return (
      <button className={props.mainPage ? "headerButton " : "headerButton2 "} onClick={props.clickHandler}>
       {props.name}
      </button>
    );
  }
  
  export default MainHeaderButton;