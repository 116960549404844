import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux'
import PropTypes from 'prop-types';
import {isMobile} from 'react-device-detect';
import './tarieven.css';


function Tarieven(props) {


     
      const renderTariefWeb = (data) => (
        <div key={Date.now()} className="case-bg bg-bleu">
            <div className="useCaseContentWrapper" >
                <div className="useCaseImgHolder">
                    {/* <img src={getPicture(uc.logo)} className="useCaseImg"  alt="Logo"/>  */}
                </div>
                <div className="useCaseTxtHolder">
                    <div className="plat italic blauw useCasePadding">Amsterdam Metro</div>
                    <h1 className="h1 blauw useCasePadding" >Naam metro station</h1>
                    <div className="plat blauw useCasePadding" />
                </div>
            </div>
        </div>
      );

      const renderTariefMob =(props) => (
        <div key={Date.now()} >
            
        </div>
      );

      
        return (
            <div className="container tarief-bg">

                { window.gtag('event', 'page_view', {
                'page_title': 'Tarieven',
                'screen_name' : 'Tarieven'})
                }
               {!isMobile && renderTariefWeb(props)}
               {isMobile && renderTariefMob(props)}
            </div>   
        )
      
        
    
}

const mapStateToProps = state => (
    {
      tarief_data: state.tarief_data

    }
  )
  
export default withRouter(connect(mapStateToProps)(Tarieven));


Tarieven.contextTypes = {
  router: PropTypes.object,
}



