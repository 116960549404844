
import './buttons.css';

function SocialButtons(props) {
 
    return (
      <div className="smWrapper">
        <a href='https://www.facebook.com/Dutchital-Media-106330284884230?utm_source=website&utm_medium=button&utm_campaign=dmsite' target={"blank"}>
          <button className="smButton smFB"/>
        </a>
        <a href='https://www.linkedin.com/company/dutchital-media/?utm_source=website&utm_medium=button&utm_campaign=dmsite' target={"blank"}>
          <button className="smButton smLi"/>
        </a>
        <a href='https://www.instagram.com/dutchitalmedia/?utm_source=website&utm_medium=button&utm_campaign=dmsite' target={"blank"}>
          <button className="smButton smIn"/>
        </a>
      </div>
    );
  }
  
  export default SocialButtons;