

function HomeButtons(props) {
 
    return (
        <button className="home-teaserItem plat" onClick={props.clickHandler}>
            <img src={props.image} className="home-teaserItemImagebox" alt=""/>
            <div className="home-teaserbox-content">
            <span>{props.name}</span>
            </div>
        </button>
    );
  }
  
  export default HomeButtons;