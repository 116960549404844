import React, {useState } from 'react'
import { GoogleMap, useJsApiLoader, Marker, InfoWindow, Circle } from '@react-google-maps/api';
import {isMobile} from 'react-device-detect';
import InnerImageZoom from 'react-inner-image-zoom';
import ButtonAdverteren from '../Buttons/buttonAdverteren';
import mapmarker from '../../image/maps-and-flags.svg'
import defaultPic from '../../image/default.jpg';
import './innerZoom.css';



const style = {
  mapContainer:{
    width: '100%',
    height: '100%',
  },
  infoWindow:{
    width: isMobile ? '100%' : '100%',
    maxWidth: isMobile ? '100%' : '350px !important',
  },
  PopUp:{
    display:"flex",
    flexDirection:"column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexWrap: "nowrap",
    width:"100%",
    maxWidth:'100%',
    borderRadius: '10px',
    overflow:"hidden"
  },
  popUpImage:{
    scaledSize: '40%',
    borderRadius: '5px',
    // height: 'auto',
    width: '45%',

  },
  companyTitel:{
    color: 'rgba(21, 43, 141, 1)',
    padding:'0% 1% 0% 1%',
    fontWeight: 'bold',
    
  },
  adres:{
    color:'rgba(21, 43, 141, 1)',
    padding:'0% 1% 0% 2%',
  },
  player:{
    color:'rgba(21, 43, 141, 1)',
    padding:'0% 1% 0% 1%',
    fontSize: '8px',

  },
}





function LocationMap(props) {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: props.apikey
  })
  
  //hooks
  const [stateInfoWindow, setStateInfoWindow] = useState({activeId: 0, isOpen: false });
 

  //options for the selectionarea (circel)
  const setCircelBoundries = (lat, lng, distance) =>{
    let circleOptions = {
      strokeColor: '#FF0000',
      strokeOpacity: 0.6,
      strokeWeight: 1,
      fillColor: '#FF0000',
      fillOpacity: 0.2,
      center: {
        lat: lat,
        lng: lng,
      },
      radius: distance,
      clickable: false,
      draggable: false,
      editable: false,
      visible: true,
      zIndex: 1,
    }
    return circleOptions;
  }


  const  getPicture = (pic) =>{
    var url ='';
    if (process.env.NODE_ENV !== 'production') {
      url = new URL('https://dutchital.csdm.cloud/casper_uploads/locatie/')
    }else{
      url = new URL('https://dutchital.csdm.cloud/casper_uploads/locatie/')
    }
    const picUrl = pic !== 'default.jpg' ? `${url}${pic}` : `${defaultPic}`
  
    if(isMobile){
      return (
        <img src={picUrl} style={style.popUpImage} alt=""/>
      )
    } else {
      return(
        <InnerImageZoom
        src={picUrl}
        width={275}
        zoomScale={2}
        zoomType='click'
        moveType='drag'
       />
      )
    }
  }

  //render of the playerdata
  const showPlayerdata = (p) => {
    return(
      <div style={{marginTop:'6px'}}>
      <div dangerouslySetInnerHTML={{ __html: p.display_name}} />
      <div dangerouslySetInnerHTML={{ __html: p.screens}} />
      <div style={{marginBottom:'6px'}} dangerouslySetInnerHTML={{ __html: p.spectators}} />
      <div style={{marginBottom:'6px'}} dangerouslySetInnerHTML={{ __html: p.comspecs}} />
      <div dangerouslySetInnerHTML={{ __html: p.aanleveren}} />
   </div>
    )
  }

   

  const createMarkers = (m) =>{
    const adres = `${m.adres} ${m.huisnummer} ${m.toevoeging_huisnummer}<br>${m.postcode}<br>${m.stad}<br>netwerk: ${m.netwerk}`;
    return(
      <Marker 
        key={m.id}
        icon={{url:mapmarker, scaledSize: new window.google.maps.Size(props.scalePointer, props.scalePointer)}}
        title={m.display_name}
        opacity={0.8}
        position={{lat: parseFloat(m.lat), lng: parseFloat(m.lng)}}
        onClick={() => setStateInfoWindow({activeId: m.id, isOpen: true })}
      >
        {stateInfoWindow.isOpen && stateInfoWindow.activeId === m.id &&
        <InfoWindow key={m.id}  style={style.infoWindow} onClick={ ()=> setStateInfoWindow({activeId: 0, isOpen: false }) }>
          <div style={style.PopUp}>
            <div style={style.companyTitel}><h3>{m.display_name}</h3></div>
            <div style={{display:'flex'}}>
              {getPicture(m.img_name)}
            </div>
            <div style={{display:'flex', width:'100%',padding: '10px 0px 0px 0px'}}>
              <div style={style.adres} dangerouslySetInnerHTML={{ __html: adres }} />
            </div>
            < ButtonAdverteren locatie={m.display_name} plaats={m.stad}/>
            {m.players && m.players.map(showPlayerdata )}
          </div>
        </InfoWindow>
        }
      </Marker>
     )
  }

  const options = {
    disableDefaultUI: true,
  }
  

  return isLoaded ? (
      <GoogleMap
        mapContainerStyle={style.mapContainer}
        options={options}
        center={{lat: parseFloat(props.center.lat), lng: parseFloat(props.center.lng)}}
        zoom={props.zoom}
        
      >
      {<Circle options={setCircelBoundries(parseFloat(props.center.lat),parseFloat(props.center.lng),props.radius)} />}
      {props.data && props.data.map(createMarkers)}
        
      </GoogleMap>
  ) : <></>
}

export default LocationMap;




