
const style = {
    button: {
        width: "100%",
        height: "35px",
        backgroundColor: "rgba(255, 120, 0, 1)",
        cursor: "pointer",
        borderRadius: "4px",
        filter: "drop-shadow(1px 1px 1px rgba(0,0,0,0.5))",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        margin: "5px 0px"
    },
    text: {
        color: "rgba(21, 43, 141, 1)",
        textDecoration: "none",
        fontWeight: 700,
    }
}

function ButtonAdverteren(props) {

    const to='ikwiladverteren@dutchitalmedia.nl';
    const subject = `Ik%20wil%20graag%20adverteren%20op%20${props.locatie}%20${props.plaats}.`
    const body =`Geachte%20heer/mevrouw,%0D%0A%0D%0AIk%20wil%20graag%20adverteren%20op%20de%20locatie%20van%20${props.locatie}%20(${props.plaats}).%0D%0A%0D%0AKunt%20u%20contact%20met%20mij%20opnemen%20voor%20een%20aanbod.%0D%0A`
 
    return (
        <div style={style.button}>
            <a style={style.text} href={`mailto:${to}?subject=${subject}&body=${body}`} >Ik wil hier adverteren!</a>
        </div>
    );
  }
  
  export default ButtonAdverteren;










