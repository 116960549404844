import React, {useState, useEffect} from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import {isMobile} from 'react-device-detect';

import LocationMap from '../../components/location/LocationMap';
import PostcodeInput from '../../components/location/postcodeInput'

import './locations.css';

const ApiKey = "AIzaSyDoaUU4qkqCcaPdXx6NgRm4N-jwD0MeEOY";

function  getUrl(endpoint){
  let url ='';
    if (process.env.NODE_ENV !== 'production') {
     url = new URL(`https://dutchital.csdm.cloud/api/v1/${endpoint}`)
    }else{
      url = new URL(`https://dutchital.csdm.cloud/api/v1/${endpoint}`)
    }
    return url;
}

const DUMMY_CENTER = {
  lat: "52.214365",
  lng: "5.969732"
};


function Locations(props) {

  
  const [zoom, setZoom] = useState();
  const [center, setCenter] = useState();
  const [markerData, setMarkerData] = useState([]);
  const [message, setMessage] = useState('');
  const [scalePointer, setScalePointer] = useState(35);
  const [radius, setRadius] = useState(0);
  const [loading, setLoading] = useState(false);

  const getGeoData = (lat,lng, distance) => {
    setLoading(true);
    fetch(getUrl(`location/locations_search/${lat}/${lng}/${distance}`))
      .then(response => response.json())
     .then(items => {
        setScalePointer(25);
        setZoom(8);
        setCenter(items.center);
        setMessage(items.message);
        setMarkerData(items.data);
        setLoading(false);
     })
  }

  const renderCircel = (d,c) => {
    setRadius((d*1000))
    setCenter(c)
  }

  const reset = () => {
    setZoom(8);
    setCenter(DUMMY_CENTER);
    setMarkerData([]);
    setMessage('');
    renderCircel(0,DUMMY_CENTER)
  }

  useEffect(()=>{
    setZoom(8);
    setCenter(DUMMY_CENTER);
    setMarkerData([]);
    setMessage('');
  },[]);

  const renderWeb = () => (
    <div className="portfolioWrapper">
      <div className="portfolioHeaderInput">
        <PostcodeInput 
          callbackfunction={getGeoData} 
          callbackDistance ={renderCircel}
          callbackReset={reset} 
          message={message} 
          apikey={ApiKey}
          loading={loading}/>
          
      </div>
      <div className="mapWrapper">
        { markerData != null && <LocationMap  apikey={ApiKey} scalePointer={scalePointer} center={center} data={markerData} zoom={zoom} radius={radius}/>}
      </div>
    </div>
  );

  const renderMob= () => (
    <div className="portfolioWrapper">
       <div className="portfolioHeaderInput">
        <PostcodeInput 
          callbackfunction={getGeoData} 
          callbackDistance ={renderCircel}
          callbackReset={reset} 
          message={message} 
          apikey={ApiKey}/>
      </div>
      <div className="mapWrapper">
        { markerData != null && <LocationMap  apikey={ApiKey} scalePointer={scalePointer} center={center} data={markerData} zoom={zoom} radius={radius}/>}
      </div>
    </div>
  );
  
  
    return (
    <div className="container portfolio-bg">

      { window.gtag('event', 'page_view', {
        'page_title': 'Netwerk',
        'screen_name' : 'Netwerk'})
      }
       {!isMobile && renderWeb()}
       {isMobile && renderMob()}
    </div>
    );
  }
  
  export default withRouter(Locations);

  Locations.contextTypes = {
    router: PropTypes.object,
  }

 